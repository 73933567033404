'use client'

import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import styles from './SmartBanner.module.scss'
import iconApp from '@/assets/images/icons/icon-app.png'
import ActionButton from '@/components/form/ActionButton/ActionButton'
import { APP_LINK } from '@/constants'
import { getCookieValue, setCookie } from '@/utils/cookie'

function SmartBanner() {
  const [isVisible, setIsVisible] = useState(true)

  const visibleHeight = '3.125rem' //size(50) // スマートバナーの高さ

  useEffect(() => {
    if (!isMobile) return

    const storedVisibility = getCookieValue('smartBannerDismissed')

    if (storedVisibility === 'true') {
      setIsVisible(false)
      document.documentElement.style.setProperty(
        '--smart-banner-height',
        '0rem',
      )
    } else {
      setIsVisible(true)
      document.documentElement.style.setProperty(
        '--smart-banner-height',
        visibleHeight,
      ) // スマートバナーの高さ
    }
  }, [])

  const dismissBanner = () => {
    setIsVisible(false)
    setCookie('smartBannerDismissed', 'true', 60 * 60 * 24) // 1日
    document.documentElement.style.setProperty('--smart-banner-height', '0rem')
  }

  return (
    <>
      {isVisible && (
        <div className={styles.smartBanner}>
          <div className={styles.content}>
            <img src={iconApp.src} alt='App Icon' className={styles.appIcon} />
            <div className={styles.text}>
              <strong>公式アプリをダウンロード</strong>
              <span>より快適にご利用いただけます</span>
            </div>
            <a href={APP_LINK} className={styles.downloadButton}>
              ダウンロード
            </a>
          </div>
          <ActionButton onClick={dismissBanner} size='xSmall'>
            ×
          </ActionButton>
        </div>
      )}
    </>
  )
}

export default SmartBanner
