'use client'

import styles from './ActionButton.module.scss'

type Props = {
  children: React.ReactNode
  onClick?: () => void
  type?: 'button' | 'submit'
  disabled?: boolean
  size?: 'xSmall' | 'small' | 'fullWidth' | 'default'
  /** 参照: src/styles/partials/_variables.scss */
  color?: string
  bgColor?: string
  outlined?: boolean
  isLoading?: boolean
}

function ActionButton({
  children,
  onClick,
  type = 'button',
  disabled = false,
  size = 'default',
  color = 'white',
  bgColor = 'primary',
  outlined = false,
  isLoading = false,
}: Props) {
  return (
    <button
      className={`${styles.actionButton} ${styles[size]}`}
      type={type}
      onClick={onClick}
      style={generateStyle({ color, bgColor, outlined })}
      disabled={isLoading || disabled}
    >
      {isLoading ? <div className={styles.loader} /> : children}
    </button>
  )
}

const generateStyle = ({
  color,
  bgColor,
  outlined,
}: Pick<Props, 'color' | 'bgColor' | 'outlined'>): React.CSSProperties => {
  if (outlined) {
    return {
      color: `var(--color-${color})`,
      border: `2px solid var(--color-${color})`,
      background: `var(--color-${bgColor})`,
    }
  }

  return {
    border: `2px solid var(--color-${bgColor})`,
    color: `var(--color-${color})`,
    background: `var(--color-${bgColor})`,
  }
}

export default ActionButton
